<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <Table
      class="align-middle"
      rowKey="id"
      :isLoading="$store.getters['tasks/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['tasks/get'](params)"
    >
      <template #top>
        <div class="p-16 border-bottom d-flex">
          <TaskFilter />
          <router-link
            class="ms-auto"
            :to="`/guru/jadwal/${$route.params.schedule_id}/tugas/tambah`"
            v-tooltip="'Tambah'"
            ><PlusCircleIcon
          /></router-link>
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import taskRepository from '../../../../api/repositories/taskRepository';
import Schedule from '../../../../store/models/Schedule';

import ScheduleInfo from '../../../../components/student/schedule/ScheduleInfo.vue';
import Table from '../../../../components/table/Table.vue';
import RowActions from '../../../../components/table/RowActions.vue';
import TaskFilter from '../../../../components/teacher/task/TaskFilter.vue';

import FileEarmarkTextIcon from 'bootstrap-icons/icons/file-earmark-text.svg';
import PencilIcon from 'bootstrap-icons/icons/pencil.svg';
import PlusCircleIcon from 'bootstrap-icons/icons/plus-circle.svg';
import TrashIcon from 'bootstrap-icons/icons/trash.svg';

export default {
  components: { ScheduleInfo, Table, TaskFilter, PlusCircleIcon },

  computed: {
    schedule() {
      return Schedule.query()
        .withAllRecursive()
        .find(this.$route.params.schedule_id);
    },

    params() {
      if (!this.schedule) return {};

      return merge(
        {
          filter: pick(this.schedule, ['grade_id', 'subject_id']),
          sort: { due_time: 'asc', id: 'desc' },
        },
        this.$route.query
      );
    },

    columns() {
      return [
        { key: 'title', text: 'Judul' },
        {
          key: 'file',
          text: 'File',
          toHTML: (row) =>
            row.file &&
            `<a class="btn btn-sm btn-outline-secondary" href="${row.fileURL}" target="_blank">Download</a>`,
        },
        {
          key: 'due_time',
          text: 'Batas Waktu',
          toHTML: (row) =>
            row.due_time && row.dueTimeJS.format('YYYY-MM-DD HH:mm'),
        },
        {
          key: 'actions',
          component: RowActions,
          componentProps: {
            actions: () => [
              {
                icon: FileEarmarkTextIcon,
                text: 'Jawaban',
                onClick: ({ row }) =>
                  this.$router.push(
                    `/guru/jadwal/${this.$route.params.schedule_id}/tugas/${row.id}/jawaban`
                  ),
              },
              {
                icon: PencilIcon,
                text: 'Ubah',
                onClick: ({ row }) =>
                  this.$router.push(
                    `/guru/jadwal/${this.$route.params.schedule_id}/tugas/${row.id}/ubah`
                  ),
              },
              {
                icon: TrashIcon,
                text: 'Hapus',
                attrs: { class: 'text-danger' },
                onClick: this.handleRemoveClick,
              },
            ],
          },
          props: { class: 'text-end' },
        },
      ];
    },

    meta() {
      return this.$store.getters['schedules/getMeta'](this.params);
    },
  },

  metaInfo() {
    return {
      title: 'Tugas',
    };
  },

  methods: {
    handleRemoveClick({ row }) {
      this.$confirmation.confirm(
        `Anda yakin mau menghapus <b>${row.title}</b>?`,
        {
          onOK: async () => {
            try {
              await taskRepository.destroy(row.id);
              this.$store.dispatch('tasks/fetchAndInvalidate', this.params);
              this.$alert.success('Tugas berhasil dihapus');
            } catch (error) {
              this.$alert.requestError(error);
            }
          },
        }
      );
    },

    fetch() {
      return this.$store.dispatch('tasks/maybeFetch', this.params);
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.schedule_id },
      });
    }

    this.fetch();

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
    ]);
  },
};
</script>
